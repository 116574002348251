<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Продажи

        vuecrud(selectedtable = "wb_sales", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")

</template>

<script>

export default {
  name: 'Partner_Incomes',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false,
        where: {
        }
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.delete('id');
      cols.delete('partner');
      cols.delete('number');
      cols.delete('odid');
      cols.delete('gNumber');

      cols.set('incomeID', {label: "Номер поставки", hasFilter: false, });
      cols.set('date', {label: "Дата", hasFilter: false, });
      cols.set('lastChangeDate', {label: "Обновлено", hasFilter: false, });
      cols.set('supplierArticle', {label: "Ваш артикул", hasFilter: false, });
      cols.set('techSize', {label: "Размер", hasFilter: false, });
      cols.set('barcode', {label: "Баркод", hasFilter: false, });
      cols.set('quantity', {label: "Остатки", hasFilter: false, });
      cols.set('isSupply', {label: "Договор поставки", hasFilter: false, });
      cols.set('isRealization', {label: "Договор реализации", hasFilter: false, });
      cols.set('subject', {label: "Предмет", hasFilter: false, });
      cols.set('category', {label: "Категория", hasFilter: false, });
      cols.set('brand', {label: "Бренд", hasFilter: false, });
      cols.set('totalPrice', {label: "Цена из УПД", hasFilter: false, });
      cols.set('warehouseName', {label: "Склад", hasFilter: false, });

      cols.set('orderId', {label: "Номер заказа", hasFilter: false, });
      cols.set('nmId', {label: "ID товара", hasFilter: false, });

      cols.set('promoCodeDiscount', {label: "Промо-код", hasFilter: false, });
      cols.set('countryName', {label: "Страна", hasFilter: false, });
      cols.set('oblastOkrugName', {label: "Область", hasFilter: false, });
      cols.set('regionName', {label: "Регион", hasFilter: false, });
      cols.set('spp', {label: "Согласованная скидка постоянного покупателя", hasFilter: false, });
      cols.set('forPay', {label: "К перечислению поставщику", hasFilter: false, });
      cols.set('finishedPrice', {label: "Фактическая цена из заказа (со всеми скидками)", hasFilter: false, });
      cols.set('priceWithDisc', {label: "Базовая цена для рассчёта перечислений поставщику", hasFilter: false, });
      cols.set('IsStorno', {label: "Продажа сторнирована", hasFilter: false, });


      return cols;

    },
  }
}
</script>
